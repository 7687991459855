<template>
  <div>
    <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile height="64" width="100%" class="document-viewpage" flat>
      <v-layout row wrap align-center class="ma-0 px-2 py-0">
        <v-flex xs1 sm1>
          <v-btn icon small dark @click="$parent.removeActiveModule">
            <v-icon size="20"> mdi-arrow-left </v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs7 sm9>
          <v-badge :content="total" inline :value="total">
            <span class="font-weight-bold px-1" :style="`color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || 'white') }`"> {{ $t('documents') }} </span>
          </v-badge>
        </v-flex>
        <v-flex xs4 sm2 class="px-0">
          <template v-if="recordObj.accesscontrol.add && recordObj.accesscontrol.edit">
            <v-row>
              <v-col class="pa-0"  cols="12">
                <v-btn color="transparent" fab x-small class="ml-2" id="relatebtn" @click="uploadDialogHandler()"
                  elevation="0" dark>
                  <v-icon> mdi-upload </v-icon>
                </v-btn>
                <v-btn color="transparent" fab x-small class="ml-2" id="relatebtn" @click="createFolder"
                  elevation="0" dark>
                  <v-icon> mdi-folder-plus-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-flex>
      </v-layout>
      <template #extension v-if="breadCrumbItems && breadCrumbItems.length">
        <v-card width="100%" tile flat>
          <v-card-title :class="['pa-0 px-1', $vuetify.theme.dark ? '#1e1e1e' : 'grey lighten-3']">
            <v-layout row wrap align-center class="ma-0">
              <v-fade-transition>
                <v-breadcrumbs class="pa-2" :items="breadCrumbItems">
                  <template #item="{ item }">
                    <v-hover #default="{ hover }">
                      <v-breadcrumbs-item :class="getBreadCrumbItemIndex(item) !== (breadCrumbItems.length - 1) ? `pointer-hand ${ hover ? 'custom_breadcrumb_item_style_hover' : ''}` : ''"
                        @click="getBreadCrumbItemIndex(item) !== (breadCrumbItems.length - 1) ? navigateToItem(item) : null">
                        <span v-if="getBreadCrumbItemIndex(item) === (breadCrumbItems.length - 1)"
                          :class="['font-weight-bold grey--text', $vuetify.theme.dark ? 'text--lighten-2' : 'text--darken-1']">
                            {{ item.name }}
                        </span>
                        <span :class="['grey--text', $vuetify.theme.dark ? '' : 'text--darken-1']" v-else> {{ item.name }} </span>
                      </v-breadcrumbs-item>
                    </v-hover>
                  </template>
                  <template #divider>
                    <v-icon> mdi-forward </v-icon>
                  </template>
                </v-breadcrumbs>
              </v-fade-transition>
            </v-layout>
          </v-card-title>
        </v-card>
      </template>
    </v-app-bar>
    <v-container fluid :class="['px-2 py-3', $vuetify.theme.dark ? '' : 'grey lighten-3']" v-if="loading">
      <v-row no-gutters>
        <v-col v-for="(item) in 11" :key="item" cols="12" sm="6" lg="3">
          <div>
            <v-skeleton-loader class="mx-auto mb-2" type="list-item"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card flat tile :color="$vuetify.theme.dark ? '' : '#f7f7f7'" v-else-if="listOfDocuments.length">
      <template>
        <v-row no-gutters class="mt-0 pl-2 pr-2">
          <v-col cols="12" sm="6" v-for="(document, docIndex) in listOfDocuments" :key="docIndex" :class="$vuetify.breakpoint.smAndUp ? 'pl-2' : ''"
            @click="document.is_document ? navigateHandler(document) : loadChildrenItems(document)">
            <v-card outlined class="mb-1" :color="$vuetify.theme.dark ? '#1e1e1e' : '#fff'" >
              <v-layout row wrap align-center class="ma-0 px-3 py-3">
                <v-flex xs1>
                  <v-icon size="20" :color="document.isdirectory ? '#ffda6b' : ''">
                    {{ document.isdirectory ? 'mdi-folder': 'mdi-file' }}
                  </v-icon>
                </v-flex>
                <v-flex xs10 class="px-4">
                  <span class="font-weight-medium subtitle-2 mb-n1 text-truncate"> {{ document.name }} </span>
                </v-flex>
                <v-flex xs1 class="text-end">
                  <v-menu>
                    <template #activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" size="15"> mdi-dots-vertical </v-icon>
                    </template>
                    <v-card>
                      <v-list dense class="custom-dense-list pt-0 pb-0">
                        <template v-for="(action, index) in document.isvirtualdoc ? virtualDocActions : document.isdirectory ? folderActions : fileActions">
                          <v-list-item :key="index" @click="action.click(document)" v-if="action.isShow !== undefined ? action.isShow : true"
                             dense>
                            <v-list-item-title class="caption">
                              <v-icon :color="$vuetify.theme.dark ? '' : action.color" size="20" :class="action.icon === 'mdi-border-color' ? 'mt-1' : ''" class="subtitle-2 mb-3 mt-3 mr-2">
                                {{ action.icon }}
                              </v-icon>
                              {{ action.tooltip }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <v-alert prominent icon="mdi-information" text class="ma-2 mt-6 pa-5" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-else>
      <span class="font-weight-medium px-3"> {{ $t('noResults') }} </span>
    </v-alert>
    <!--Upload or Create Dialog-->
    <v-dialog v-model="uploadDocumentDialog" width="500" persistent>
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`">{{ $t('upload') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-form ref="uploadForm">
            <v-file-input type="file" outlined show-size v-model="file" hide-details :rules="$_requiredValidation" :label="$t('file')" dense></v-file-input>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="success" class="mr-2" @click="uploadDocumentHandler" :loading="documentFormloading">{{ $t('ok') }}</v-btn>
            <v-btn color="error" @click="resetDocumentHandler">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
      <!-- <form-template :references.sync="propertiesFormReference" @closeDialog="closeDocumentDialogHandler()" :model="propertyModel.properties" ref="documentReference"></form-template> -->
    </v-dialog>
    <!--Folder rename Dialog-->
    <v-dialog v-model="folderRenameDialog" width="550" persistent>
      <v-card>
        <v-card-title :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }; color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || '#fff') }`"
        class="px-4 py-3 body-1">
          {{ isCreateFolder ? $t('createFolder') : $t('rename') }}
          <v-spacer></v-spacer>
            <v-btn icon  @click="resetRenameModel" title="Close" :color="$vuetify.theme.dark ? '' : 'white'">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form ref="renameForm" autocomplete="off">
            <v-text-field hide-details dense v-model="renameObj.name" outlined :label="$t('name')" :rules="$_requiredValidation" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="isCreateFolder ? renameFolder() : renameFolderOrFileHandler()" :loading="renameLoading" class="text-capitalize pa-4" small> {{ $t('ok') }} </v-btn>
          <v-btn color="error" @click="resetRenameModel" class="text-capitalize pa-4" small> {{ $t('close') }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to handle Recipents -->
    <v-dialog v-model="recipientDialog" width="650" persistent>
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('addRecipents') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-form ref="recipentForm">
            <v-simple-table dense width="100%" class="dense_table bordered--table mt-2 recipent-form-table">
              <thead>
                <tr>
                  <th>{{ $t('name') }} *</th>
                  <th>{{ $t('email') }} *</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(recipient, i) in listOfRecipients" :key="i">
                  <td width="45%">
                    <v-text-field dense hide-details v-model="recipient.name" :rules="$_requiredValidation"></v-text-field>
                  </td>
                  <td width="52%">
                    <v-text-field dense hide-details v-model="recipient.email" :rules="recipient.email ? $_emailValidation : $_requiredValidation"></v-text-field>
                  </td>
                  <td width="3%">
                    <v-btn color="secondary" text fab dark x-small class="mt-1 pb-0" @click="removeRecipients(i)"><v-icon dark>mdi-minus-circle</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-flex class="text-start">
              <v-btn color="success" x-small class="mt-2 mb-0 pb-0" fab @click="addRecipientItem"><v-icon>mdi-plus</v-icon></v-btn>
            </v-flex>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" @click="saveRecipientHandler" :loading="renameLoading" :disabled="listOfRecipients.length === 0">{{$t('send')}}</v-btn>
          <v-btn small color="error" @click="closeRecipientDialog">{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Envelope details-->
    <v-dialog v-model="envelopeDialog"  width="650" persistent>
      <div class="customtext">
      <v-card>
        <v-card-title class="pa-2"> {{ $t('details') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-row class="mx-0">
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold">{{ $t('subject') }} : </span> {{ envelopeObj.subject }}
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold">{{ $t('message') }} : </span> {{ envelopeObj.message }}
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold">{{ $t('status') }} : </span>
              <v-icon :color="getColor(envelopeObj.envelope_status)" x-small class="pr-2">mdi-checkbox-blank-circle</v-icon>
              <span id="text-style">{{ envelopeObj.envelope_status }}</span>
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold"> {{ $t('recipients') }} </span>
              <v-list>
                <template v-for="(item, index) in envelopeObj.recipients">
                  <v-list-item :key="index" class="pa-0 envelope">
                    <v-list-item-action>{{index + 1}}</v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-medium">{{ item.email }}</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon :color="getColor(item.status)" x-small class="pr-2">mdi-checkbox-blank-circle</v-icon>
                        {{ item.status ?  `${ item.status[0].toUpperCase()}${item.status.slice(1)}` : '' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.declined_reason }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" @click="envelopeDialog = false">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
      </div>
    </v-dialog>
    <!--Preview dialog for eml / msg file-->
    <v-dialog v-model="previewDialog" fullscreen persistent transition="dialog-bottom-transition" v-if="previewDialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark  @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('previewFile') }}</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-row>
            <table class="pl-5">
              <tr v-if="previewContent.from.email">
                <td><p class="black--text pt-4 mb-0" ><strong> {{ $t('from') }}: </strong>  </p></td>
                <td><p class="black--text pt-4 mb-0" >  {{ previewContent.from.email }}</p></td>
              </tr>
              <tr v-if="previewContent.date_on">
                <td><p class="black--text my-0"><strong> {{ $t('sentOn') }}: </strong> </p></td>
                <td><p class="black--text my-0"> {{ previewContent.date_on }}</p></td>
              </tr>
              <tr v-if="previewContent.to">
                <td><p class="black--text my-0"><strong> {{ $t('to') }}: </strong></p></td>
                <td><p class="black--text my-0">  {{previewContent.to}}</p></td>
              </tr>
              <tr v-if="previewContent.cc">
                <td><p class="black--text my-0"><strong> {{ $t('cc') }}: </strong>  </p></td>
                <td><p class="black--text my-0">  {{previewContent.cc}} </p></td>
              </tr>
              <tr v-if="previewContent.bcc">
                <td><p class="black--text my-0"><strong> {{ $t('bcc') }}: </strong> </p></td>
                <td><p class="black--text my-0"> {{previewContent.bcc}}</p></td>
              </tr>
              <tr v-if="previewContent.subject">
                <td><p class="black--text my-0" ><strong> {{ $t('subject') }}: </strong> </p></td>
                <td><p class="black--text my-0"> {{previewContent.subject}}</p></td>
              </tr>
              <tr v-if="previewContent.attachments">
                <td><p class="black--text my-0 pb-5"><strong> {{ $t('attachments') }}: </strong></p></td>
                <td><p class="black--text my-0 pb-5">{{previewContent.attachments}}</p></td>
              </tr>
            </table>
          </v-row>
          <v-card-text>
            <div v-html="previewContent.body"></div>
          </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Route Dialog -->
    <v-dialog v-model="routeDialog" fullscreen transition="dialog-bottom-transition" width="1500" v-if="routeDialog">
      <document-route :routeObj="routeObj" :recordName="name" v-if="routeDialog" :moduleObj="moduleObj" :key="reRenderRoute" :hasDocumentRedirectId="hasDocumentRedirectId"></document-route>
    </v-dialog>
    <!--Workflow Dialog-->
    <v-dialog v-model="workflowDialog" fullscreen transition="dialog-bottom-transition" width="1000" v-if="workflowDialog">
      <document-workflow :workflowObj="workflowObj" v-if="workflowDialog"></document-workflow>
    </v-dialog>
    <!-- Workflow Detail -->
    <v-dialog width="1200" fullscreen v-model="workflowDetailsViewDialog">
      <workflow-detail v-if="workflowDetailsViewDialog" :workflowDetail="workflowDetail" :recordName="name"></workflow-detail>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
// import documentHelper from '@/components/documents/document_upload'
import { mapGetters } from 'vuex'
export default {
  props: ['sharepointId', 'name', 'recordObj', 'formattedObj'],
  // mixins: [documentHelper],
  data () {
    return {
      dialog: false,
      isCreate: false,
      listOfTemplates: [],
      documentHeaders: [],
      listOfFiles: [],
      file: [],
      loading: false,
      // listOfFields: [],
      uploadDocumentDialog: false,
      moduleObj: {
        moduleId: this.$route.params.id,
        moduleName: this.$route.params.name,
        recordId: this.$route.params.record_id
      },
      propertyModel: {},
      formProps: [],
      listOfProperties: [],
      listOfDocuments: [],
      dynamicFormFields: [],
      dateFieldsForConversion: [],
      versionHistory: [],
      versionHistoryDialog: false,
      documentFormloading: false,
      /* pwa */
      payload: {
        addClickHandler: this.documentDialog,
        uploadHanlder: this.documentDialog,
        refreshHandler: this.getListOfDocuments,
        moduleTitle: 'Documents',
        loading: false,
        list: [],
        items: [],
        completeList: []
      },
      currentPage: 1,
      recordPerPage: 8,
      hasNextPage: true,
      accesscontrol: false,
      saveLoading: false,
      fieldsLoading: false,
      renameModel: {},
      folderRenameDialog: false,
      renameLoading: false,
      breadCrumbItems: [],
      removeFromIndex: 0,
      isCreateFolder: false,
      activeFolder: null,
      templateDialog: false,
      templateObj: {},
      templateFieldsList: [],
      templateCreateDialog: false,
      templateFileName: '',
      previewContent: {},
      previewDialog: false,
      signatureObj: {},
      showSignatureDialog: false,
      listOfRecipients: [],
      recipientDialog: false,
      envelopeDialog: false,
      envelopeObj: {},
      userFullDetails: {},
      // convertPdfAllowedTypes: ['jpg', 'png', 'jpeg', 'tiff', 'bmp', 'doc', 'docx', 'txt']
      convertPdfAllowedTypes: ['doc', 'docx', 'txt'],
      templateFieldLoading: false,
      reRenderRoute: 0,
      routeDialog: false,
      hasDocumentRedirectId: null,
      workflowDialog: false,
      workflowDetailsViewDialog: false,
      routeObj: {},
      total: 0,
      renameObj: {
        isFile: false
      },
      folderObj: {
        name: ''
      }
    }
  },
  components: {
    FormTemplate: () => import('@/components/FormTemplate'),
    versions: () => import('@/components/documents/Versions'),
    'document-route': () => import('@/components/documents/Router'),
    'document-workflow': () => import('@/components/documents/Workflow'),
    'workflow-detail': () => import('@/components/documents/WorkflowDetails')
  },
  watch: {
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
    },
    'signatureObj.choiceMade' (val) {
      if (val === 2) {
        this.listOfRecipients = []
      }
    }
  },
  computed: {
    ...mapGetters(['formType', 'listOfFields', 'listOfModules', 'userDetails', 'getUsers']),
    actionItems () {
      return [{
        icon: '',
        click: ''
      }]
    },
    fileActions () {
      return [
        ...([{
          tooltip: this.$t('download'),
          icon: 'mdi-cloud-download',
          color: 'accent',
          click: (document) => this.downloadHandler(document)
        }]),
        ...(this.recordObj.accesscontrol.edit ? [
          {
            tooltip: this.$t('rename'),
            icon: 'mdi-border-color',
            color: 'success',
            click: (document) => this.renameFolderFormShow(document)
          }
          // {
          //   tooltip: this.$t('route'),
          //   icon: 'mdi-text-box-check-outline',
          //   color: 'teal',
          //   click: (document) => this.openRouteDialog(document)
          // }
        ] : []),
        ...(this.recordObj.accesscontrol.delete ? [
          {
            tooltip: this.$t('delete'),
            icon: 'mdi-delete',
            color: 'error',
            click: (document) => this.deleteHandler(document)
          }
        ] : [])]
    },
    folderActions () {
      return [
        ...(this.recordObj.accesscontrol.edit ? [
          {
            tooltip: this.$t('rename'),
            icon: 'mdi-border-color',
            color: 'success',
            click: (document) => this.renameFolderFormShow(document)
          }
          // {
          //   tooltip: this.$t('route'),
          //   icon: 'mdi-text-box-check-outline',
          //   color: 'teal',
          //   click: (document) => this.openRouteDialog(document)
          // }
        ] : []),
        ...(this.recordObj.accesscontrol.delete ? [
          {
            tooltip: this.$t('delete'),
            icon: 'mdi-delete',
            color: 'error',
            click: (document) => this.deleteHandler(document)
          }
        ] : [])]
    }
  },
  created () {
    this.getListOfDocuments(true)
  },
  mounted () {
    this.setPermission()
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === 'azurestorage') {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
        this.$store.commit('hideDialog')
        this.getListOfDocuments()
      }
    })
    if (this.$route.query && this.$route.query.docid) {
      this.hasDocumentRedirectId = this.$route.query.docid
    }
    const userFullDetails = this.getUsers.find(x => x._id === this.userDetails._id)
    this.userFullDetails = userFullDetails
    this.$eventBus.$on('refreshList', (data) => {
      this.getListOfDocuments(false)
    })
    this.$eventBus.$on('openRouteDialog', (data) => {
      this.routeObj.document = data
      this.getWorkFlows(data)
    })
    this.$eventBus.$on('closeRouteDialog', () => {
      this.routeDialog = false
    })
    this.$eventBus.$on('openWorkflowDialog', (data) => {
      this.workflowDialog = true
      this.workflowObj = data
    })
    this.$eventBus.$on('closeWorkflowDialog', (data) => {
      if (data) this.getListOfDocuments()
      this.hasDocumentRedirectId = null
      window.history.replaceState(null, null, window.location.pathname)
      this.workflowDialog = false
    })
    this.$eventBus.$on('closeWorkflowDetail', () => {
      this.workflowDetailsViewDialog = false
      this.hasDocumentRedirectId = null
      if (window.location) window.history.replaceState(null, null, window.location.pathname)
    })
    this.$eventBus.$on('reloadWorkflowDialog', (data) => {
      if (data) this.getWorkFlows(data)
    })
  },
  methods: {
    // getListOfDocuments () {
    //   this.loading = true
    //   this.payload.loading = true
    //   this.$api.execute('get', `documents/${this.moduleObj.moduleName}/get_documents/${this.moduleObj.recordId}`).then((response) => {
    //     this.listOfDocuments = response.data
    //     this.payload.list = response.data
    //     // this.payload.completeList = response.data
    //     // this.changePage(1)
    //     this.$api.execute('get', `documenttemplates/get_by_module/${this.moduleObj.moduleId}`).then(response => {
    //       this.listOfTemplates = response.data
    //     })
    //   }).finally(() => {
    //     this.loading = false
    //     this.payload.loading = false
    //   })
    // },
    getListOfDocuments (isRoot) {
     this.loading = true
      if (this.breadCrumbItems.length > 1 && !isRoot) {
        this.loadChildrenItems(this.breadCrumbItems[this.breadCrumbItems.length - 1], true)
      } else {
        this.$api.execute('get', `azurestorage/get_documents_and_folders/${this.recordObj._id}?module=${this.moduleObj.moduleName}`).then((response) => {
          this.listOfDocuments = response.data.parentfolderitems.items
          if (this.breadCrumbItems.length > 1) {
            this.breadCrumbItems.splice(1, this.breadCrumbItems.length)
            this.activeFolder = this.breadCrumbItems[0]
          } else {
            const breadcrumbItem = { id: this.recordObj.azuresharepath, isRoot: true, name: this.recordObj.azuresharepath, path: this.recordObj.azuresharepath }
            this.activeFolder = breadcrumbItem
            const obj = this.breadCrumbItems.find(x => x.id === this.activeFolder.id)
            if (obj === null || obj === undefined) this.breadCrumbItems.push(breadcrumbItem)
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    defineActions (document) {
      const text = document.name.split('.')
      if (text.length > 0) {
        const type = text[text.length - 1]
        let actions = []
        if (this.convertPdfAllowedTypes.includes(type)) {
          actions = [{
            tooltip: this.$t('convertToPdf'),
            icon: 'mdi-file-pdf',
            color: 'teal',
            // click: (documentObj) => this.convertToPdf(documentObj)
            click: (documentObj) => this.documentDialogConvertPDf('create', documentObj)
          }]
        }
        if (type === 'msg' || type === 'eml') {
          actions = [...actions, ...this.documentActions, ...this.documentsplActions]
          return actions
        } else if (this.userDetails.integrations && this.userDetails.integrations.docusign && (type === 'doc' || type === 'docx' || type === 'pdf' || type === 'xlsx')) {
          actions = [...actions, ...this.documentActions]
          if (this.accesscontrol) actions.splice(actions.length - 1, 0, this.docusignActions[0])
          else actions = [...actions, ...this.docusignActions]
          return actions
        } else {
          return [...actions, ...this.documentActions]
        }
      }
    },
    updateDocumentMeta () {
      this.documentFormloading = true
      const parentId = this.moduleObj.recordId
      this.propertyModel.parent_id = parentId
      let model = this.$formatter.cloneVariable(this.propertyModel.properties)
      model = Object.assign({ ...model }, { parent_id: parentId })
      // const transformData = { date: { list: this.dateFieldsForConversion, from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
      // model = this.$formatter.formatModel(model, transformData)
      const finalModel = {}
      this.dynamicFormFields.forEach(ele => {
        // eslint-disable-next-line
        if (!ele.readonly && model.hasOwnProperty(ele.name)) {
          finalModel[ele.name] = model[ele.name]
        }
      })
      this.$api.execute('put', `sharepoint/update_doc_meta_data/${this.propertyModel.id}`, finalModel)
        .finally(() => {
          this.documentFormloading = false
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
          this.closeDocumentDialogHandler()
          this.getListOfDocuments()
        })
    },
    resetDocumentHandler () {
      this.$refs.uploadForm.reset()
      this.uploadDocumentDialog = false
    },
    uploadDialogHandler () {
      this.uploadDocumentDialog = true
      // this.$refs.uploadForm.reset()
    },
    uploadDocumentHandler () {
      if (this.$refs.uploadForm.validate()) {
        this.documentFormloading = true
        let formData = new FormData()
        if (this.file) formData.append(this.file.name, this.file)
        this.$api.execute('post', `azurestorage/upload_document/${this.recordObj._id}/${this.moduleObj.moduleName}?current_folder_path=${this.activeFolder.path}`, formData).then(() => {
          this.getListOfDocuments()
        }).finally(() => {
          this.resetDocumentHandler()
          this.documentFormloading = false
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    openDialogHanlder (type) {
      if (type === 'create') this.isCreate = true
      this.dialog = true
    },
    closeDialogHandler () {
      this.file = []
      this.isCreate = false
      this.dialog = false
    },
    renameFolder () {
      if (this.$refs.renameForm.validate()) {
        this.renameLoading = true
        this.loading = true
        this.saveLoader = true
        let model = {
          id: this.recordObj._id,
          module_id: this.moduleObj.moduleId,
          path: this.activeFolder.path,
          name: this.renameObj.name
        }
        this.$api.execute('post', 'azurestorage/create_folder', model).then(async () => {
          this.getListOfDocuments()
        }).finally(() => {
          // this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: text })
          this.resetRenameModel()
          this.renameLoading = false
          this.loading = false
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    resetRenameModel () {
      this.renameObj = {}
      this.folderRenameDialog = false
      this.$refs.renameForm.reset()
    },
    loadChildrenItems (item, dontAdd) { // dont add item to breadcrumb if dontadd is true
      let breadcrumbItem = {}
      this.payload.loading = true
       if (item && !dontAdd) {
        let path = this.recordObj.azuresharepath
        if (this.breadCrumbItems.length === 1) breadcrumbItem.path = `${path}/${item.name}`
        else breadcrumbItem.path = `${this.breadCrumbItems[this.breadCrumbItems.length - 1].path}/${item.name}`
        breadcrumbItem = { ...breadcrumbItem, id: item.name, name: item.name }
        this.activeFolder = this.$formatter.cloneVariable(breadcrumbItem)
      } else breadcrumbItem.path = item.path
      // this.activeFolder = this.$formatter.cloneVariable(item)
      // const breadcrumbItem = { id: item.id, name: item.name, path: item.path }
      this.loading = true
      this.$api.execute('get',`azurestorage/get_folder_items/${this.recordObj._id}/${this.moduleObj.moduleName}?current_folder_path=${breadcrumbItem.path}`).then((response) => {
        const documents = response.data
        // add folder to breadcrumb
        if (this.removeFromIndex !== 0) {
          this.breadCrumbItems.splice(this.removeFromIndex, this.breadCrumbItems.length)
          this.removeFromIndex = 0
        }
        if (!dontAdd) this.breadCrumbItems.push(breadcrumbItem)
        this.listOfDocuments= documents.items
      }).finally(() => {
        this.loading = false
        // this.payload.loading = false
      })
    },
    navigateToItem (item) {
      this.removeFromIndex = this.getBreadCrumbItemIndex(item)
      if (item.isRoot) {
        this.getListOfDocuments(true) // do force load to not check conditon of breadcrumb active item
      } else {
        this.loadChildrenItems(item, true)
      }
    },
    getBreadCrumbItemIndex (item) {
      return this.breadCrumbItems.indexOf(item)
    },
    createFolder () {
      this.isCreateFolder = true
      this.renameFolderFormShow()
    },
    setPermission () {
      const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleObj.moduleId, this.userDetails)
      this.accesscontrol = hasManagePermission
    },
    addRecipientItem () {
      this.listOfRecipients.push({})
    },
    removeRecipients (index) {
      this.listOfRecipients.splice(index, 1)
    },
    saveRecipientHandler () {
      if (this.$refs.recipentForm.validate()) {
        const recipientArray = this.$formatter.cloneVariable(this.listOfRecipients)
        var valueArr = recipientArray.map((item) => { return item.email })
        var containsDuplicate = valueArr.some((item, idx) => {
          // eslint-disable-next-line
          return valueArr.indexOf(item) != idx
        })
        if (containsDuplicate) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'differentEmail' })
        } else {
          let recipients = {}
          var model = {
            doc_id: this.signatureObj.id,
            subject: this.signatureObj.subject,
            message: this.signatureObj.message
          }
          recipientArray.forEach(recipent => {
            recipients = { ...recipients, [recipent.email]: recipent.name }
          })
          model.recipients = recipients
          this.renameLoading = true
          this.$api.execute('post', `docusign/share_document/${this.signatureObj.id}`, model).then(response => {
            if (!response.data.signed_already) {
              window.open(response.data.url, '_self')
            } else {
              // this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'document signed already' })
              this.envelopeObj = response.data
              this.envelopeDialog = true
            }
          }).finally(() => {
            this.renameLoading = false
            this.closeRecipientDialog()
            this.closeSignatureDialog()
          })
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    closeRecipientDialog () {
      this.listOfRecipients = []
      this.recipientDialog = false
    },
    closeSignatureDialog () {
      Object.keys(this.signatureObj).forEach(k => delete this.signatureObj[k])
      this.listOfRecipients = []
      this.showSignatureDialog = false
    },
    clearTemplateValues (index) {
      this.templateFieldsList.relationmodulefields[index].relatedRecords = []
      this.templateFieldsList.relationmodulefields[index].selected = null
      if (this.templateFieldsList.relationmodulefields[index].fields) {
        this.templateFieldsList.relationmodulefields[index].fields.forEach(item => {
          item[item.placeholder] = ''
        })
      }
    },
    getColor (status) {
      switch (status) {
        case 'sent': return 'blue'
        case 'delivered': return 'blue'
        case 'completed': return 'green'
        case 'correct': return '#ffce00'
        case 'declined': return 'red'
        case 'Voided': return 'grey'
      }
    },
    getStatusText (status) {
      let resultFound = this.listOfWorkflowStatus.find(x => x.id === status)
      if (resultFound) return resultFound.name
      else return ''
    },
    openRecipentDialog () {
      this.recipientDialog = true
    },
    openCreateTemplateDialog () {
      this.templateDialog = true
      this.getTemplates()
      this.payload.addClickHandler('create')
    },
    getWorkFlows (document) {
      this.$api.execute('get', `documentworkflows/get_workflows/${document.id}`).then(response => {
        if (response.data && response.data.length > 0) {
          this.routeObj.showWorkflows = true
          this.routeObj.listOfWorkflows = response.data
        } else {
          this.routeObj.showWorkflows = false
          this.routeObj.listOfWorkflows = []
        }
        if (this.hasDocumentRedirectId) {
          let result = response.data.find(x => x._id === this.$route.query.workflowid)
          if (result) {
            this.workflowDetail = result
            this.workflowDetail.document = document
            this.workflowDetailsViewDialog = true
          }
        } else {
          this.routeObj.document = document
          this.routeDialog = true
          this.reRenderRoute++
        }
      })
    },
    renameFolderFormShow (document) {
      if (!this.isCreateFolder) {
        this.renameObj = {
          name: document.name,
          oldName: document.name,
          path: this.activeFolder.path,
          isFile: !document.isdirectory
        }
      }
      this.folderRenameDialog = true
    },
    downloadHandler (document) {
      this.$api.execute('get', `azurestorage/download/${this.moduleObj.moduleName}?current_folder_path=${this.activeFolder.path}&file_name=${document.name}`).then((response) => {
        window.open(`${process.env.VUE_APP_URL}files/download?fileName=${response.data}`, '_blank')
      })
    },
    deleteHandler (document) {
      let payload = { url: `azurestorage/delete/${this.moduleObj.moduleName}?path=${this.activeFolder.path}/${document.name}&isdirectory=${document.isdirectory}`, module: 'azurestorage', hasOnlyUrl: true }
      this.$store.commit('showDeleteDialog', payload)
    },
    renameFolderOrFileHandler () {
      this.renameLoading = true
      this.$api.execute('put', this.renameObj.isFile ? `azurestorage/rename_file/${this.moduleObj.moduleName}` : `azurestorage/rename_folder/${this.moduleObj.moduleName}`, this.renameObj).then(() => {
        this.getListOfDocuments()
      }).finally(() => {
        this.renameLoading = false
        this.folderRenameDialog = false
        this.renameObj = {
          isFile: false
        }
      })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('refreshList')
    this.$eventBus.$off('openRouteDialog')
    this.$eventBus.$off('closeRouteDialog')
    this.$eventBus.$off('openWorkflowDialog')
    this.$eventBus.$off('closeWorkflowDialog')
    this.$eventBus.$off('reloadWorkflowDialog')
    this.$eventBus.$off('closeWorkflowDetail')
  }
}
</script>
<style>
/* .documents_custom_scroll_style {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
} */
/* the below is added by vinoth from web */
.pointer-hand {
  cursor: pointer !important;
}
.custom_breadcrumb_item_style_hover {
  background: #e0e0e0;
}
.customtext .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
  color: unset !important;
}
#text-style {
  text-transform: capitalize !important;
}
.envelope .v-list-item__action {
  min-width: 0px !important;
}
/* the above is added by vinoth from web */
.recipent-form-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px !important;
}
.document-viewpage .v-toolbar__content {
  padding: 0 !important;
}
.document-viewpage .v-toolbar__extension {
  padding: 0 !important;
}
.custom-dense-list .v-list-item {
  min-height: 20px !important;
}
</style>
